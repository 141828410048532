@import '../styles/variables';
@import '../styles/mixins';

.#{$ui-row-prefix}-reviews {
  @include text-crop(6px, 5px);
  display: flex;
  align-items: center;

  &__rating-number {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    margin-right: 3px;
    color: $andes-text-color-secondary;
    position: relative;
    top: 0.5px;
  }

  &__ratings {
    display: flex;
    margin-right: 2px;
  }

  &__amount {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    color: $andes-text-color-secondary;
  }

  .#{$search-prefix}-icon--star {
    height: 10px;
    margin: 0 1px;
    width: 10px;
    fill: transparent;
    stroke: $andes-blue-500;
  }
}
