@import '../../styles/variables';

.#{$ui-row-prefix}-item__highlight-label {
  $root: &;
  border-radius: $border-radius-3;
  display: table-cell;
  font-weight: $font-weight-semibold;
  padding: 2px 4px;
  width: fit-content;
  margin-bottom: $andes-spacing-12;
  max-height: $font-size-18;
  line-height: $font-size-14;
  min-height: $font-size-18;

  &--no-background {
    padding: 0;
  }

  &--meli_choice {
    display: flex;
    vertical-align: middle;
    align-items: center;
    width: fit-content;
    padding: 1px 5px 0 3px;
    height: 20px;

    span {
      line-height: $andes-spacing-16;
    }
  }

  &__container {
    display: flex;
    gap: 4px;
  }

  &--financeable_by_mc &__container {
    align-items: center;
  }

  &--media {
    #{$root}__container {
      align-items: flex-end;

      > svg {
        height: 11px;
      }

      #{$root}__text {
        font-size: $font-size-10;
        margin-left: 3px;
      }
    }
  }

  &__text {
    padding: 0 4px 2px;
  }

  .#{$ui-row-prefix}-icon {
    min-width: 16px;
    position: relative;
    top: 1px;

    &--meli {
      top: 0;
    }

    &--ahora30 {
      margin-top: 3px;
    }
  }

  .ui-search-icon {
    position: relative;
    top: 0;

    &--melichoice-logo {
      height: 13px;

      img {
        width: 19px;
        height: 13px;
      }
    }
  }
}
