@import '../../styles/variables';
@import '../../styles/mixins';

.#{$ui-row-prefix}-item__brand-discoverability {
    color: $ui-row-text-color;
    font-weight: 600;
    font-size: $font-size-12;
    margin-bottom: -$ui-row-spacing-14 !important;
    line-height: 1.3;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    padding-top: $ui-row-spacing-2;
    @include text-crop(4px, 0);
  }
  