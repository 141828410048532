@import './value-proposition';

.#{$ui-row-prefix}-value-proposition {
  line-height: 14px;

  &__pills {
    margin-right: 3px;
  }

  .#{$ui-row-prefix}-item__highlight-label {
    font-size: $font-size-12;
  }
}

.#{$ui-row-prefix}-value-propositions-list {
  @include text-crop(5px, 3px)
}

@media (min-width: 360px) {
  .#{$ui-row-prefix}-value-proposition {
    min-width: 183px;
  }
}
