.#{$ui-row-prefix}-winner-alternatives {
  &--separator-top {
    border-top: 1px solid rgba(0, 0, 0, 0.071);
    padding-top: 8px;
  }

  &--chevron {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.102);
    border-radius: 4px;
    padding: 8px;
  }

  &--sandwich {
    $border: 1px solid rgba(0, 0, 0, 0.071);
    border-top: $border;
    border-bottom: $border;
    padding: 8px 16px 8px 0;
    margin-bottom: 8px;
  }

  &--border-grey,
  &--border-blue {
    border-radius: 4px;
    padding: 12px 8px;
    margin-bottom: 4px;
  }

  &--border-grey {
    border: 1px solid rgba(0, 0, 0, 0.071);
  }

  &--border-blue {
    border: 2px solid #3483fa;
  }

  &__link {
    text-align: start;
    border: none;
    background: none;
    padding: 0;
    width: 100%;
    position: relative;
    z-index: 20;
  }
}
