@import '../../styles/variables';
@import '../../styles/mixins';

// sass-lint:disable no-vendor-prefixes
.#{$ui-row-prefix}-official-store-label {
  font-size: $font-size-12;
  color: $andes-gray-550;
  margin-left: 0;
  -webkit-box-ordinal-group: 4;
  clear: left;
  display: block;
  font-weight: 400;
  line-height: $line-height-16;
  position: relative;
  margin-bottom: 0;
  @include text-crop(4px, 3px);

  &__container {
    display: flex;
    align-items: center;

    .ui-row-icon--cockade {
      margin-left: $andes-spacing-4;
    }
  }
}
