@import '../../styles/variables';
@import '../../styles/mixins';

.#{$ui-row-prefix}-installments {
  font-size: $font-size-12;
  margin-left: 0;
  clear: left;
  display: block;
  font-weight: $font-weight-regular;
  line-height: $line-height-16;
  position: relative;
  @include text-crop(4px, 4px);

  .#{$ui-row-prefix}-price {
    margin-right: 5px;
    display: inline-block;

    .ui-row-price__part {
      margin-right: 0;
    }

    .price-tag-symbol {
      margin-right: 3px;
    }

    .price-tag-cents {
      padding-top: 0;
      font-size: $font-size-8;
    }
  }

  &-prefix {
    color: $andes-black;
    display: inline-flex;
    margin: 0 3px 0 0;
    align-items: baseline;

    .#{$ui-row-prefix}-price__part {
      margin-left: 0;
    }
  }

  &-space {
    margin-top: 4px;
  }

  &-pill {
    background: rgba(0, 166, 80, 0.1);
    border-radius: 3px;
    color: #00a650;
    display: inline;
    font-weight: 600;
    line-height: 1.5;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    padding: 0 4px;
    margin: 4px 4px 0 0;

    &__section {
      display: inline-block;
      margin-bottom: 1px;
      white-space: pre-wrap;
      margin-right: 4px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}


