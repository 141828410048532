@import '../../../styles/variables';

.#{$ui-row-prefix}-rebates {
  margin-bottom: $ui-row-spacing-12;

  &-rebate {
    margin-bottom: $ui-row-spacing-4;
    display: flex;
    flex-direction: column;

    &__pill {
      margin: 0;
      margin-right: $ui-row-spacing-6;
      font-size: $font-size-14;
      border-radius: $ui-row-blue-pill-radius;
      font-weight: $font-weight-semibold;
    }

    &__text {
      font-size: $font-size-12;
      color: $ui-row-gray-450;
      display: table-cell;
      padding-left: $ui-row-spacing-6;
    }

    &__text_nl {
      font-size: $font-size-12;
      color: $ui-row-gray-450;
      font-weight: $font-weight-regular;
    }
  }

  .ui-row-item__highlight-label__text {
    padding: 0 4px 2px;
  }
}

.#{$ui-row-prefix}-price__discount {
  transform: translate(-1px, 1px);
}

.#{$ui-row-prefix}-price__discount_prefix {
  color: $ui-row-gray-r080;
  font-size: $font-size-14;
  margin-right: $andes-spacing-4;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
