@import './layout';
@import '../bookmark/bookmark';
@import '../actions/actions';
@import './variables';
@import './functions';
@import './pb';

//sass-lint:disable no-color-keywords
//sass-lint:disable hex-length

.#{$ui-row-prefix}-color--BLACK {
  color: ui_row_color( $andes-black);
}

.#{$ui-row-prefix}-color--LIGHT_GREEN {
  color: ui_row_color(LIGHT_GREEN);
}

.#{$ui-row-prefix}-color--GRAY {
  color: ui_row_color($andes-gray-550);
}

.#{$ui-row-prefix}-size--XXX_SMALL {
  font-size: size(XXX_SMALL);
}

.#{$ui-row-prefix}-size--XX_SMALL {
  font-size: size(XX_SMALL);
}

.#{$ui-row-prefix}-size--X_SMALL {
  font-size: size(X_SMALL);
}

.#{$ui-row-prefix}-size--SMALL {
  font-size: size(SMALL);
}

.#{$ui-row-prefix}-size--MEDIUM {
  font-size: size(MEDIUM);
}

.#{$ui-row-prefix}-size--LARGE {
  font-size: size(LARGE);
}

.#{$ui-row-prefix}-size--X_LARGE {
  font-size: size(X_LARGE);
}

.#{$ui-row-prefix}-weight--LIGHT {
  font-weight: weight(LIGHT);
}

.#{$ui-row-prefix}-weight--REGULAR {
  font-weight: weight(REGULAR);
}

.#{$ui-row-prefix}-weight--SEMIBOLD {
  font-weight: weight(SEMIBOLD);
}

.#{$ui-row-prefix}-weight--BOLD {
  font-weight: weight(BOLD);
}
