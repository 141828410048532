
@import "../../styles/variables";
@import "../../styles/mixins";


$price-component-prefix: #{$ui-row-prefix}-price;

.#{$price-component-prefix} {
  $self: &;
  letter-spacing: normal;
  font-weight: $font-weight-regular;
  color: $ui-row-gray-1000;
  @include text-crop(4px, 3px);

  &__front-price {
    font-size: $font-size-12;
    font-weight: $font-weight-bold;
  }

  &__part {
    display: flex;
    width: fit-content;
  }

  &__original-value {
    font-size: 0.5em;
    color: $andes-gray-550;
  }

  .price-tag-cents {
    line-height: 1.1em;
    padding-top: 1px;
  }

  .price-tag-symbol {
    margin-right: 5px;
  }

  &__second-line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &--bold {
      #{$self}__part {
        font-weight: $font-weight-semibold;
        margin-right: 0.5em;
      }
    }

    &__label {
      // font-size: 0.5em;
      color: $andes-success-green;
      // display: flex;
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
    }

    .andes-money-amount.andes-money-amount.ui-row-price--size-x-tiny {
      font-size: 16px;

      .andes-money-amount__currency-symbol {
        font-size: 12px;
      }

      .andes-money-amount__fraction {
        font-size: 12px;
      }

      .andes-money-amount__cents.andes-money-amount__cents--superscript-16 {
        font-size: 8px!important;  // necesary to override andes superscript-16 style
        margin-top: 0!important;   // necesary to override andes superscript-16 style
      }
    }

    .andes-money-amount.andes-money-amount.ui-row-price--size-tiny {
      font-size: 16px;

      .andes-money-amount__currency-symbol {
        font-size: 12px;
      }

      .andes-money-amount__fraction {
        font-size: 12px;
      }

      .andes-money-amount__cents.andes-money-amount__cents--superscript-16 {
        font-size: 8px!important;  // necesary to override andes superscript-16 style
        margin-top: 0!important;   // necesary to override andes superscript-16 style
      }
    }
  }

  &--size-x-tiny {
    font-size: $font-size-12;
  }

  &--size-tiny {
    font-size: $font-size-14;
  }

  &--size-small {
    font-size: $font-size-16;
  }

  &--size-medium {
    font-size: $font-size-20;

    .andes-money-amount.ui-row-price--size-medium.ui-row-price__original-value {
      font-size: 16px!important;

      .andes-money-amount__currency-symbol {
        font-size: 16px;
      }

      .andes-money-amount__fraction {
        font-size: 16px;
      }

      .andes-money-amount__cents.andes-money-amount__cents--superscript-20 {
        margin-top: 1px!important;      // necesary to override andes superscript-20 style
      }
    }
  }

  &--size-large {
    font-size: $font-size-36;
    font-weight: $font-weight-light;

    #{$self}__original-value {
      font-weight: $font-weight-regular;
    }
  }

  .ui-row-icon--loyalty {
    margin-right: $andes-spacing-4;
    position: relative;
    top: 1px;
  }
}

