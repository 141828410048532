/* PILLS BUILDER */

.#{$ui-row-prefix} {
  &-card {
    .ui-pb {
      margin-bottom: $andes-spacing-16;
      &--bottom-pills {
        margin-top: -2px;
        margin-bottom: $andes-spacing-12;
      }
    }
  }
}

.ui-fulfillment-pill {
  .ui-pills-builder-icon,
  .ui-pb-icon  {
    max-height: 12px;
    transform: translateY(4px);
  }
}

.meli-plus,
[class~="meli_plus"],
.meliplus {
  .ui-pb-highlight-wrapper {
    &::after {
      display: inline-block;
      width: 4px;
      height: auto;
    }
  }
}

