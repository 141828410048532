@import '../../styles/variables';
@import '../../styles/mixins';

.#{$ui-row-prefix}-item__title {
  color: $ui-row-text-color;
  font-weight: 500;
  line-height: 1.3;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  @include text-crop(4px, 0);
}

.#{$ui-row-prefix}-item__title-compats {
  -webkit-line-clamp: 6;
}
