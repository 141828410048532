.ui-row-item {
  &-spotlight {
    &__highlight {
      display: flex;
      font-size: 14px;
      gap: 1px;
      margin-bottom: 12px;
      margin-top: -2px;
      align-items: center;
    }

    .group-text {
      line-height: 1;
      & span {
        margin-right: 2px;
      }
    }
  }
}
