@import '../../../styles/variables';
@import '../../../styles/mixins';

.#{$ui-row-prefix}-price-details {
    display: flex;
    align-items: center;
    font-weight: $font-weight-light;

    .#{$ui-row-prefix}-price--size-tiny {
      color: inherit;
    }

    .#{$ui-row-prefix}-price--size-tiny .#{$ui-row-prefix}-price__part.price-tag {
      font-weight: $font-weight-light;

      .price-tag-symbol {
        margin-right: 0.2em;
      }

      .price-tag-cents {
        font-weight: $font-weight-light;
        line-height: 1;
        padding-top: 0;
      }
    }
}
