.#{$ui-row-prefix}-value-proposition {
  color: $ui-row-gray-800;
  letter-spacing: -0.2px;
  cursor: pointer;

  &__pills {
    display: inline-block;
  }

  &__pill {
    display: flex;
    margin-left: 3px;
    margin-top: 4px;

    &:first-child {
      margin-left: 0;
    }
  }
}
