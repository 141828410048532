@mixin text-crop($top-adjustment, $bottom-adjustment) {
  &::before,
  &::after {
    content: '';
    display: block;
    height: 0;
    width: 0;
  }

  &::before {
    margin-top: -#{$top-adjustment};
  }

  &::after {
    margin-bottom: -#{$bottom-adjustment};
  }
}
