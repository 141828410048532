@import '../../styles/variables';
@import '../../styles/mixins';

.#{$ui-row-prefix}-item__promise__text {
  border-radius: $border-radius-3;
  font-size: $font-size-12;
  font-weight: $font-weight-semibold;
  line-height: $font-size-12 + 2;
  padding: 5px 4px 6px;
  @include text-crop(3px, 3px);
}

.#{$ui-row-prefix}-item__promise {
  .#{$ui-row-prefix}-item__promise__text {
    margin-right: $andes-spacing-8;
  }

  &--same_day .#{$ui-row-prefix}-item__promise__text {
    background-color: $ui-row-green-100;
    color: $andes-white;
  }

  &--turbo .#{$ui-row-prefix}-item__promise__text {
    background-color: $ui-row-green-100;
    color: $andes-white;
  }

  &--next_day .#{$ui-row-prefix}-item__promise__text {
    background-color: $ui-row-green-50;
    color: $ui-row-green-100;
  }
}

.#{$ui-row-prefix}-item__promise__text--splitted {
  .#{$ui-row-prefix}-item__promise__text:first-of-type {
    margin-right: -5px;
  }
}

