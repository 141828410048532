@import './variations';

@mixin variations-common {
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  justify-content: center;
  object-fit: contain;
  span {
    color: $ui-row-action-color;
    font-weight: $font-weight-regular;
    font-size: $font-size-12;
  }
}

@mixin img-44 {
  width: 44px;
  height: 44px;
}

.#{$ui-row-prefix}-variations-pickers {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;

  &__variation {
    border: 1px solid $andes-gray-070;
    border-radius: $ui-row-spacing-4;
    margin-right: $ui-row-spacing-8;
    @include variations-common();

    &-selected {
      border: 2px solid $ui-row-action-color;
      border-radius: 3px;
      margin-right: 7px;
      @include variations-common();
    }
  }

  &__link {
    position: relative;
    z-index: 2;
  }
}

@media (max-width: 359px) {
  .#{$ui-row-prefix}-variations-picker-mobile {
    &__variation {
      @include img-44();
      &-selected {
        @include img-44();
      }
    }
  }
}

@media (max-width: 330px) {
  .#{$ui-row-prefix}-variations-picker-mobile {
    margin-left: -($ui-row-spacing-6);
  }
}
