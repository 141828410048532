@import '../../styles/variables';
@import '../../styles/mixins';

.#{$ui-row-prefix}-item {

  &__shipping {
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    line-height: $font-size-14;
    @include text-crop(3px, 3px);
    &--free {
      color: $andes-success-green;
    }
  }
}
