.ui-row-main__actions {
  display: flex;
}

.ui-row__action {
  color: $ui-row-action-color;
  font-size: 14px;
  padding: 0;

  + .ui-row__action {
    margin-left: 16px;
  }
}
