@import 'price-details';

.#{$ui-row-prefix}-price-details {
  font-size: $font-size-12;
  margin-top: $andes-spacing-8;
  margin-bottom: -8px;

  .andes-money-amount__currency-symbol {
    font-size: 12px;
  }

  .andes-money-amount__fraction {
    font-size: 12px;
  }

  .andes-money-amount__cents.andes-money-amount__cents--superscript-16 {
    font-size: 8px!important;  // necesary to override andes isuperscript-16 style
    margin-top: 0!important;   // necesary to override andes isuperscript-16 style
  }
  
  .#{$ui-row-prefix}-price--size-tiny .#{$ui-row-prefix}-price__part.price-tag {
    font-size: $font-size-12;

    .price-tag-cents {
      font-size: $font-size-8;
    }
  }

  .#{$ui-row-prefix}-price::before {
    margin-top: 0;
  }

  .#{$ui-row-prefix}-price::after {
    margin-bottom: 0;
  }
}
