@import '../styles/variables';

.#{$ui-row-prefix}-bookmark {
  position: absolute;
  z-index: $z-index-xl;
  top: 16px;
  left: 120px;

  &__btn {
    background-color: $ui-row-bookmark-background-color;
    border: none;
    display: inline-flex;
    outline: none;
    padding: 8px;
    height: 32px;
    width: 32px;
    border-radius: 50%;
  }

  &__icon-bookmark,
  &__icon-bookmark-fill {
    fill: none;
    stroke: $andes-blue-500;
    height: 15px;
    overflow: visible;
    position: absolute;
    top: 9px;

    g {
      stroke-width: 1.5;
    }
  }

  &__icon-bookmark-fill {
    fill: $andes-blue-500;
    transition: opacity 0.25s ease-in-out, transform 0.35s cubic-bezier(0, 0, 0.3, 1.5);
    opacity: 0;
    transform: scale(0);

    &--active {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@media (max-width: 320px) {
  .#{$ui-row-prefix}-bookmark {
    left: 100px;
  }
}
