@import '~@andes/money-amount/index';

$container-promotion: 'container-promotion';
$search-prefix: 'ui-search';
$label-padding: 'label-padding';
$label-text: 'label-text';

.#{$search-prefix}-coupon {
  border-radius: 2px;
  display: flex;
  width: fit-content;
  margin-bottom: 1px;
  height: 18px;

  &__label {
    display: flex;
    font-weight: $font-weight-semibold;
    align-items: center;

    &__icon {
      margin-right: 4px;
      margin-top: 3px;
    }

    &__icon-mobile {
      margin-right: 4px;
      margin-top: 4px;
    }
  }
}

.#{$label-padding}-mobile {
  padding: 2px 6px 1px;
}

.#{$label-padding}-desktop {
  padding: 1px 4px;
}

.#{$label-text}-mobile {
  font-size: 12px;
  line-height: 15px;
  font-weight: 600,
}

.#{$label-text}-desktop {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600,
}

.#{$container-promotion} {
  margin-bottom: 12px;
}
