@import '../../../styles/variables';
@import './rebates';

.#{$ui-row-prefix}-rebates {
  margin-bottom: $ui-row-spacing-4;

  &-rebate {
    margin-bottom: $ui-row-spacing-4;

    &__pill {
      margin-right: $ui-row-spacing-6;
      font-size: $font-size-14;
      border-radius: $ui-row-blue-pill-radius;
      font-weight: $font-weight-semibold;
    }

    &__text,
    &__text_nl {
      font-size: $font-size-12;
      color: $ui-row-gray-450;
      font-weight: $font-weight-regular;
    }
  }
}

.#{$ui-row-prefix}-price__discount_prefix {
  color: $ui-row-gray-r080;
  font-size: $font-size-12;
  margin-right: $andes-spacing-4;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
