@import '../styles/variables';
@import '../styles/mixins';

.#{$ui-row-prefix}-icon {
  &--chevron {
    @include size(9px, 14px);
    stroke: $andes-blue-400;
  }

  &--warning {
    @include size(70px, 51px);
    fill: $ui-row-color-warning;
  }

  &--search {
    @include size(14px, 14px);
    fill: $andes-gray-550;
  }

  &--full {
    @include size(37px, 12px);
    fill: $andes-success-green;
  }

  &--loyalty {
    @include size(16px, auto);
    fill: $ui-row-green-150;
  }

  &--hidden {
    display: none;
    min-width: 0;
    max-width: 0;
    min-height: 0;
    max-height: 0;
    opacity: 0;
  }

  &--alert-new,
  &--alert-edit {

    @include size(22px, auto);
    fill: $andes-blue-400;
  }

  &--disclaimer {
    &__container {
      @include size(16px, 16px);
      background-color: $andes-blue-400;
      text-align: center;
      border-radius: 100%;
    }

    &__content {
      @include size(8px, 8px);
      fill: $andes-white;
      position: relative;
      bottom: 4px;
    }
  }

  &--meli {
    @include size(21px, 17px);
  }

  &--melichoice {
    @include size(19px, 13px);
  }

  &--supermarket-logo {
    @include size(100px, 15px);
  }
}
