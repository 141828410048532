@import './variables';

.ui-row-card {
  padding: 12px 0;
  display: flex;
  align-items: flex-start;
  box-shadow: none;
  margin: 1px 0;
  border-radius: unset;
  background: #fff;
  position: relative;

  &__link::before {
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  &__item-wrapper {
    display: flex;
  }

  & > div {
    flex: 1;
  }

  &__item-image-container {
    margin: 0 12px;
    width: 144px;
    position: relative;
    display: table-cell;
    vertical-align: top;

    &--core {
      position: relative;
      display: table-cell;
      vertical-align: top;
    }
  }

  // link contiene figure and image
  &__image-link {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
  }

  //figure
  &__image-container {
    width: 144px;
    position: relative;
    vertical-align: top;
    border-radius: 6px;
    margin: 0;
    overflow: hidden;
    text-align: center;
    display: flex;

    img {
      border-radius: 6px;
      height: auto;
      width: auto;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto;
      object-fit: contain;
    }

    &--core {
      min-height: 144px;
      max-height: 192px;

      .ui-row-card__overlay-image {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.04);
      }
    }

    &--mot, &--res, &--srv {
      height: 144px;
    }
  }

  &__crop-image {
    img {
      object-fit: cover;
      width: 100%;
    }
  }

  // estilos para search core items
  &-core__item-wrapper {
    display: table;
    width: 100%;
    height: 100%;
  }

  &__content {
    padding: 4px 0;
    &--core {
      margin-top: 0;
    }
  }

  .ui-row-item-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ui-search-threshold-label {
      font-size: 12px;
      padding-right: 12px;
    }

    .ui-row-card__cpg-wrapper {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: space-between;

      .ui-row-reviews {
        padding-bottom: 8px;
      }

      .ui-search-card-add-to-cart {
        z-index: 9;
        position: relative;
        padding-right: 12px;
      }
    }
  }

  .ui-row-item__ad-label {
    font-size: 14px;
    line-height: $line-height-16;
    color: $ui-row-gray-600;
    padding: 13px 12px 0 8px;
    align-self: flex-end;

    // sass-lint:disable nesting-depth
    &--blue {
      color: #3483fa;
    }
  }


  a,
  a:hover {
    color: $andes-black;
  }

  // sass-lint:disable class-name-format
  .ui-row-color--LIGHT_GREEN {
    color: $ui-row-green-100;
  }

  .ui-row-item__highlight-label {
    &--pricing_rebates {
      font-size: $font-size-12;
    }
  }

  .ui-row-installments {
    font-size: $font-size-12;
  }

  .ui-row-price {
    &__discount_prefix {
      font-size: $font-size-12;
    }

    &__discount {
      font-size: $font-size-12;
    }

    .price-tag-cents {
      font-size: $font-size-10;
      font-weight: $font-weight-semibold;
    }
  }

  .#{$ui-row-prefix}-item__highlight-label {

    &.#{$ui-row-prefix}-value-proposition__pill {
      margin-bottom: 0;
    }
  }
}

.andes-card--padding-default>.andes-card__content.ui-row-card {
  padding: 12px 0;
}

@media (max-width: 320px) {
  .ui-row-card {
    &__item-image-container {
      width: 124px;
    }

    &__image-container {
      width: 124px;

      &--core {
        min-height: 124px;
        max-height: 164px;
      }

      &--mot, &--res, &--srv {
        height: 124px;
      }
    }

  }
}

.ui-row-card .ui-row-item__shops-pills--absolute {
  display: none;
  left: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 85px;
  z-index: 1;

  .ui-pb {
    margin: 0;
    width: 100%;
  }

  .ui-search__discount--shops.ui-pb-label-builder {
    border-radius: 0;
    flex-direction: column;
    height: 40px;
    justify-content: center;
    padding: $andes-spacing-4;
    width: 100%;

    .ui-pb-label:nth-child(1) {
      font-size: $font-size-14;
    }

    .ui-pb-label:nth-child(2) {
      font-size: $font-size-10;
    }
  }

  .ui-row-item__group__element--shipping {
    width: 100%;
  }

  .ui-row-item__shipping.ui-row-item__shipping--free {
    border-radius: 0;
    height: 20px;
    font-size: $font-size-12;
    font-weight: $font-weight-regular;
    margin: 0;
    padding: $andes-spacing-4 $andes-spacing-8;
    width: 100%;
  }
}

.ui-row-card .ui-row-item__mot-pills--builder {
  .ui-pb {
    gap: unset;
  }

  .ui-pb-container {
    .ui-pb-label {
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
      line-height: unset;
    }

    .andes-money-amount,
    .andes-money-amount__currency-symbol,
    .andes-money-amount__fraction,
    .andes-money-amount__cents {
      font-size: $font-size-12;
      font-weight: $font-weight-regular;
      top: 0px
    }
  }
}
