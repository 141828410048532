@import '../../styles/variables';
@import '../../styles/mixins';

// sass-lint:disable no-vendor-prefixes
.#{$ui-row-prefix}-item__attributes {
  list-style: none;
  padding: 0;
  font-size: $font-size-12;
  -webkit-box-orient: vertical;
  line-height: $line-height-16;
  @include text-crop(4px, 4px);

  li {
    display: inline;
    color: rgba(0,0,0,.45);
    font-size: 12px;
    font-weight: 400;
  }

  &--mot {
    li{
      &:after {
        content: "\b7";
        font-size: 200%;
        vertical-align: sub;
        line-height: 12px;
        margin-right: 2px;
        margin-left: 3px;
      }

      &:last-child{
        &:after {
          display: none;
        }
      }
    }
  }
}
