@import './price';

.#{$price-component-prefix}__second-line {
  .#{$price-component-prefix}__part {
    font-weight: $font-weight-regular;
    margin-right: $ui-row-spacing-4;
  }
}

.ui-row-item__discount--below {
  .ui-row-price__second-line {
    flex-direction: column;
    align-items: flex-start;
  }

  &.ui-row-item__group__element {
    margin-bottom: 7px;

    .ui-row-price__second-line .ui-row-price__part {
      line-height: 1.25;
    }

    .ui-row-price__second-line__label {
      line-height: 1.25;
      text-wrap: wrap;
    }
  }
 }
