@import '../../styles/variables';
@import '../../styles/mixins';
.#{$ui-row-prefix} {
  &-item__group {
    margin-bottom: $andes-spacing-16;

    &:last-child {
      margin-bottom: 0;
    }

    &--rebates {
      margin-bottom: $andes-spacing-4;
    }

    &__element-separator {
      display: block;
      width: 100%;
      height: .1px;
      margin: 0;
      border: none;
    }

    &__element {
      margin-bottom: $andes-spacing-12;
      &.ui-row-item__price-container {
        .ui-row-price .ui-row-item__pds-best-price {
          margin-bottom: $andes-spacing-8;
          margin-top: 2px;
        }

        .ui-row-item__price-label {
          color: #333;
          font-size: 17px;
          font-weight: 400;
          @include text-crop(6px, 5px);
        }
      }

      &--shipping {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }

      .#{$ui-row-prefix}-item__shipping {
        margin-right: $andes-spacing-4;
      }

      .#{$ui-row-prefix}-item__shipping--free {
        font-weight: 600;
      }

      .#{$ui-row-prefix}-item__promise {
        display: inline-flex;
        flex-wrap: wrap;
      }

      .#{$ui-row-prefix}-item__promise .#{$ui-row-prefix}-item__fulfillment {
        display: inline-block;
        margin-top: 3px;
      }

      .#{$ui-row-prefix}-item__promise span:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }

      .#{$ui-row-prefix}-item__promise__text--last {
        margin-right: $andes-spacing-4;
      }

      .#{$ui-row-prefix}-item__promise__text--long {
        margin-bottom: 0;
      }

      .#{$ui-row-prefix}-item__fulfillment {
        display: inline-flex;
        height: 10px;
        margin: -1px 0;
      }

      &.ui-row-item__location {
        color: #8c8c8c;
        font-size: 12px;
        line-height: 16px;
        font-weight: 500;
        @include text-crop(4px, 4px);
        display: block;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &-separator {
        display: block;
        width: 100%;
        height: 0.1px;
        margin: 0;
        border: none;
      }
    }

    &--title {
      margin-bottom: 12px;
    }

    &--vertical-highlight {
      flex-wrap: wrap;
    }

    &--good-price {
      margin-top: -$andes-spacing-4;
      margin-bottom: $andes-spacing-8;
    }

    &--verified-vehicles {
      margin-top: -$andes-spacing-4;
      margin-bottom: $andes-spacing-8;
    }

    &.#{$ui-row-prefix}-item__winner-alternatives-container {
      margin-bottom: 12px;
    }

    &.#{$ui-row-prefix}-item__winner-bottom-alternatives-container {
      margin-top: -9px;

      @media (min-width: 360px) and (max-width: 480px) {
        margin-top: 8px;
      }

    }
  }

  &-card__content {
    &--core {
      .ui-row-item__group--title {
        margin-bottom: 12px;
      }

      & > *:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.#{$ui-row-prefix}-item__pds-best-price {
  .#{$ui-row-prefix}-price__second-line {
    .#{$ui-row-prefix}-price__part {
      font-weight: $font-weight-semibold;
    }
  }
  @include text-crop(2px, 2px);
}

@media (max-width: 365px) {
  .#{$ui-row-prefix}-item__group__element {
    &--shipping {
      width: 102%;
    }
  }
}
