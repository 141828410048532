@import '../../styles/variables';
@import '../../styles/mixins';

.#{$ui-row-prefix}-variations {
  color: $andes-gray-550;
  font-size: $font-size-12;
  font-weight: $font-weight-regular;
  line-height: $line-height-s;
  @include text-crop(4px, 3px);
}
