.#{$ui-row-prefix}-composed-label-alternatives {

  &__container {
    line-height: 15px;
    margin-bottom: 2px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__items {
    display: inline-block;
  }
  
  &__value,
  &--normal-text {
    margin: 0;
    font-family: inherit;
    white-space: pre-wrap;
    vertical-align: middle;
  }
  
  &__price {
    display: inline-block;
    vertical-align: middle;
  }
  
}
