$andes-theme: 'mercadolibre';
@import '~@andes/common/index';

//sass-lint:disable no-color-keywords
//sass-lint:disable hex-length

$line-height-16: 16px;

// Prefixes
$ui-row-prefix: 'ui-row';

// Spacing
$andes-spacing-96: 96px;
$ui-row-spacing-2: 2px;
$ui-row-spacing-4: 4px;
$ui-row-spacing-5: 5px;
$ui-row-spacing-6: 6px;
$ui-row-spacing-8: 8px;
$ui-row-spacing-9: 9px;
$ui-row-spacing-10: 10px;
$ui-row-spacing-12: 12px;
$ui-row-spacing-14: 14px;
$ui-row-spacing-18: 18px;
$ui-row-spacing-20: 20px;
$ui-row-spacing-22: 22px;
$ui-row-spacing-36: 36px;
$ui-row-spacing-38: 38px;
$ui-row-spacing-42: 42px;
$ui-row-spacing-52: 52px;
$ui-row-spacing-96: 96px;

// Fonts

$font-size-8: 8px;
$font-size-9: 9px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-13: 13px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-36: 36px;

// Colors
$ui-row-gray-100: #fafafa;
$ui-row-gray-200: #f5f5f5;
$ui-row-gray-300: #f0f0f0;
$ui-row-gray-350: rgba(0, 0, 0, 0.35);
$ui-row-gray-400: #dedede;
$ui-row-gray-450: rgba(0, 0, 0, 0.45);
$ui-row-gray-500: #ddd;
$ui-row-gray-600: #bfbfbf;
$ui-row-gray-700: #999;
$ui-row-gray-800: #8c8c8c;
$ui-row-gray-900: #777;
$ui-row-gray-1000: #333;
$ui-row-gray-666: #666;
$ui-row-gray-r080: rgba(0, 0, 0, 0.8);

$ui-row-action-color: #3483fa;

$ui-row-yellow-f5cc00: #f5cc00;
$ui-row-yellow-fff1cb: #fff1cb;

$ui-row-brown-a47b2e: #a47b2e;

$ui-row-green-50: #e6f7ee;
$ui-row-green-100: #00a650;
$ui-row-green-150: #39b54a;

$ui-row-background-spotlight-off: #d8d8d8;

// Alpha Colors
$ui-row-gray-r010: rgba(0, 0, 0, 0.1);

// Component Colors
$ui-row-color-warning: $ui-row-yellow-f5cc00;
$ui-row-result-gallery-shadow-color: $ui-row-gray-r010;
$ui-row-box-bg-color: $ui-row-gray-300;
$ui-row-box-border-color: $ui-row-gray-400;
$ui-row-bg-color: $ui-row-gray-200;
$ui-row-line-color: $ui-row-gray-500;
$ui-row-text-color: $ui-row-gray-1000;
$ui-row-color-filter: $ui-row-gray-100;
$ui-row-res-location-color: $ui-row-gray-800;
$ui-row-related-item: $ui-row-gray-900;
$ui-row-ad-label-color: $ui-row-gray-600;
$ui-row-blue-pill: rgba(52, 131, 250, 0.2);
$ui-row-blue-pill-radius: 3px;
$ui-row-bookmark-background-color: rgba(255, 255, 255, 0.9);

// Z-index

$z-index-xl: 10;
$z-index-l: 8;
$z-index-m: 5;
$z-index-s: 2;
$z-index-base: 1;


$colors: (
  #000: $andes-black,
  LIGHT_GREEN: $andes-success-green,
  #808080: $andes-gray-550,
);

$sizes: (
  XXX_SMALL: $font-size-10,
  XX_SMALL: $font-size-12,
  X_SMALL: $font-size-14,
  SMALL: $font-size-16,
  MEDIUM: $font-size-18,
  LARGE: $font-size-20,
  X_LARGE: $font-size-24,
);

$weights: (
  LIGHT: $font-weight-light,
  REGULAR: $font-weight-regular,
  SEMIBOLD: $font-weight-semibold,
  BOLD: $font-weight-bold,
);
