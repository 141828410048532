@import './styles.scss';

.#{$ui-row-prefix}-icon {
  &--meli {
    margin-right: 0.5px;
  }

  &--international-logo {
    @include size(213px, 39px);
  }
}
