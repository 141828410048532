@import './highlight-label';


.#{$ui-row-prefix}-item__highlight-label {
  font-size: $font-size-10;

  &--meli_choice {
    padding-right: 4px;
  }

  .#{$search-prefix}-icon--black-friday {
    position: relative;
    top: 1.5px;
  }

  .#{$search-prefix}-icon--hot-sale {
    position: relative;
    top: 1.3px;
  }

  .#{$ui-row-prefix}-icon--cart-volume-discount {
    margin-right: 8px;
  }
  
  &__text--no-padding {
    padding: 0;
  }
  
}


